<template>
  <div class="page" :style="style()">

  </div>
</template>

<script>
export default {
  // 重定向页面
  name: 'redirect',
  data() {
    return {
      bgColor: ''
    }
  },
  methods: {

  },
  computed: {
    style() {
      return {
        // 'background-color': this.bgColor
      }
    }
  },
  mounted() {
    // 判断是否有target属性
    const target = this.$route.params.target
    // 重置数据
    window.resetBIDATA()
    // 重定向
    if (target) {
      console.log('[redirect] 检测到target,重定向到 ---> ' + target)
      this.$router.push(target)
    } else {
      console.log('[redirect] 没有检测到target,重定向到 ---> ' + target)
      this.$router.push('/home')
    }
  },
}
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
  transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
